/* global BUILD_ID */
import './polyfills';
import './vendor';
import './system.config';

( function() {
	const { SystemJS } = global;

	function addQueryParameter( url, key, value ) {
		let indexOfFragment = url.indexOf( '#' ),
			indexOfQuery = url.indexOf( '?' );
		const len = url.length;
		if( indexOfFragment < 0 ) indexOfFragment = len;
		if( indexOfQuery < 0 ) indexOfQuery = indexOfFragment;
		const root = url.slice( 0, indexOfQuery );
		let query = url.slice( indexOfQuery, indexOfFragment );
		const fragment = url.slice( indexOfFragment );
		if( query ) query += '&';
		else query += '?';
		query += encodeURIComponent( key );
		if( value !== undefined ) query += `=${encodeURIComponent(value)}`;
		return `${root}${query}${fragment}`;
	}

	function updateUrl( url ) {
		// cache-busting
		if( ( typeof BUILD_ID === 'string' ) && ( url.indexOf( BUILD_ID ) < 0 ) ) {
			url = addQueryParameter( url, BUILD_ID );
		}

		return url;
	}

	function shimBefore( obj, fnName, before ) {
		const fn = obj[ fnName ];
		obj[ fnName ] = function( ...args ) {
			args = before.apply( this, args );
			return fn.apply( this, args );
		};
	}

	function shimAfter( obj, fnName, after ) {
		const fn = obj[ fnName ];
		obj[ fnName ] = function( ...args ) {
			const retval = fn.apply( this, args );
			return after.call( this, retval, ...args );
		};
	}

	const doNotTransform = new Set;

	shimAfter( SystemJS, 'registerDynamic', ( retval, name ) => {
		doNotTransform.add( name );
		return retval;
	} );

	( function() {
		// https://github.com/systemjs/systemjs/issues/1616#issuecomment-289268010

		let metadataSymbol;
		function getMetadataSymbol( loader, mustFindKey ) {
			if( metadataSymbol ) {
				return metadataSymbol;
			}
			if( loader[ '@@metadata' ] ) {
				metadataSymbol = '@@metadata';
				return metadataSymbol;
			}
			for( const s of Object.getOwnPropertySymbols( loader ) ) {
				const lov = loader[ s ];
				if( lov && typeof lov === 'object' && lov[ mustFindKey ] ) {
					metadataSymbol = s;
					return metadataSymbol;
				}
			}
			throw new Error( 'Failed to find metadata symbol.' );
		}

		const Loader = Object.getPrototypeOf( Object.getPrototypeOf( Object.getPrototypeOf( SystemJS ) ) ).constructor;
		shimAfter( Loader.prototype, 'resolve', function( promise, key ) {
			const loader = this;
			if( !doNotTransform.has( key ) ) {
				promise = promise.then( key => {
					const newKey = updateUrl( key );
					if( newKey !== key ) {
						const metaSymbol = getMetadataSymbol( loader, key ),
							metadata = loader[ metaSymbol ],
							moveMe = metadata[ key ];
						if( moveMe ) {
							delete metadata[ key ];
							metadata[ newKey ] = moveMe;
						}
					}
					return newKey;
				} );
			}
			return promise;
		} );

		shimAfter( SystemJS, 'resolveSync', ( url, key ) => {
			if( !doNotTransform.has( key ) ) {
				url = updateUrl( url );
			}
			return url;
		} );
	}() );

	// shimBefore( global, 'fetch', ( url, ...args ) => [ updateUrl( url ), ...args ] );

	function registerExisting( name, object ) {
		if( !object ) {
			/* eslint-disable no-console */
			if( console && typeof console.warn === 'function' ) {
				console.warn( `${name} is not defined` );
			}
			/* eslint-enable no-console */
		}
		SystemJS.registerDynamic( name, [], false, ( require, exports, module ) => { module.exports = object; } );
	}
	registerExisting( 'jquery', global.$ );
	registerExisting( 'tracksc', global._TrackSC );
	registerExisting( 'telligentmodal', global.Telligent_Modal );
	registerExisting( 'tianalyticstrack', global._tiAnalyticsTrack );

	SystemJS.registerDynamic( SystemJS.resolveSync( 'rxjs/operators' ), [ 'rxjs' ], false, ( require, exports, module ) => {
		module.exports = require( 'rxjs' ).operators;
	} );
}() );
