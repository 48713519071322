SystemJS.config( {
	paths: {
		'npm:': '/utility/lib/npm/',
		'bundle:': '/utility/lib/bundles/',
		'widget:e2e8:': '/cfs-file/__key/defaultwidgets/e2e80000000000000000000000000000-'
	},
	map: {
		'widget-supportcreateeditpost': 'widget:e2e8:127cbf6cc52541639126e7d55addaa66',
		'widget-headermain': 'widget:e2e8:b5eba70bc9984fcc95aa4f1f3a2513f6',
		'widget-inboxaccountactivityoptions': 'widget:e2e8:62e91258889d48b290aba92e52c21834',
		'widget-inboxnavigation': 'widget:e2e8:c715c930d5d74b359f1dee9507d4bcfa',
		'widget-inboxsubscriptions': 'widget:e2e8:a9817e635c194648a77bf1d4222e5499',
		'widget-realtimeoptions': 'widget:e2e8:64fde4b6694d40d48ddfb6bc7e55feb1',
		'widget-sitebanner': 'widget:e2e8:8e37c7cc2be34dab910dfa4c3808ef60',
		'widget-supportanalyzer': 'widget:e2e8:64fcf2d625644af8a393f94d8028608b',
		'widget-thread': 'widget:e2e8:70eb79f7675b42078f78355560839785',
		'widget-threadlist': 'widget:e2e8:c6af9d06240940beae71ade1bf750571',
		'widget-threadtracking': 'widget:e2e8:ff387bf9cd5a4342a6a6335effa13601',
		'widget-userhover': 'widget:e2e8:212f71d69e37457887f33f32918f6ae4',
		'widget-groupbanner': 'widget:e2e8:e19958ed2a7b471db41f938e4b9d7652',
		'widget-productlinking': 'widget:e2e8:d91cbf0c85564271a6a2dd017de05d02',
		knockout: 'bundle:knockout.js',
		'knockout-components': '/utility/src/knockout-components.js',
        'product-linking': '/utility/src/product-linking.js',
		utility: '/utility/src/utility',
		'jquery-outside-events': 'bundle:jquery-outside-events.js',
		'jquery-ui': 'bundle:jquery-ui.js',
		lodash: 'bundle:lodash.js',
		'lodash-decorators': 'bundle:lodash-decorators.js',
		'sticky-table-headers': 'bundle:sticky-table-headers.js',
		'js-cookie': 'bundle:js-cookie.js',
		multiselect: 'bundle:multiselect.js',
		rxjs: 'bundle:rxjs.js',
		scrollmagic: 'bundle:scrollmagic.js',
		tablesorter: 'bundle:tablesorter.js',
		tslib: 'bundle:tslib.js',
		velocity: 'bundle:velocity.js',
		css: 'npm:systemjs-plugin-css/css.js',
		json: 'npm:systemjs-plugin-json/json.js',
		text: 'npm:systemjs-plugin-text/text.js'
	},
	packages: {
		'widget-supportcreateeditpost': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-headermain': {
			defaultExtension: 'js'
		},
		'widget-inboxaccountactivityoptions': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-inboxnavigation': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-inboxsubscriptions': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-realtimeoptions': {
			defaultExtension: 'js'
		},
		'widget-sitebanner': {
			defaultExtension: 'js'
		},
		'widget-supportanalyzer': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-thread': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-threadlist': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-threadtracking': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-groupbanner': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		'widget-productlinking': {
			main: 'main.js',
			defaultExtension: 'js'
		},
		utility: {
			defaultExtension: 'js'
		}
	},
	meta: {
		'*.css': { loader: 'css' },
		'*.less': { loader: 'css' },
		'*.htm': { loader: 'text' },
		'*.html': { loader: 'text' },
		'*.txt': { loader: 'text' },
		'*.xml': { loader: 'text' },
		'*.json': { loader: 'json' },
		'bundle:*': {
			format: 'cjs',
			deps: [ 'bundle:common.js' ]
		}
	}
} );
